import React from 'react';
import { Collapse, Icon } from 'antd';
import '../index.less';

const Panel = Collapse.Panel;
const Loading = Loader.Loading;

class UpdateInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      aboutList: [],
      loading: true
    };
  }

  componentDidMount() {
    this.queryAboutList();
  }

  queryAboutList = () => {
    Service.other.queryVersion().then(res => {
      this.setState({
        loading: false,
        aboutList: res.about
      });
    });
  };

  render() {
    const { loading, aboutList } = this.state;
    const firstUpload = aboutList[0];
    if (loading) {
      return <Loading />;
    }
    const data = aboutList.slice(1);
    return (
          <div className="about-view-content">
            {firstUpload && (
              <div className="about-view-content-first">
                <div className="content-header">最近更新</div>
                <div className="content-title">
                  {firstUpload.name} {firstUpload.time}
                </div>
                <div className="content-content">
                  {firstUpload.function.map(item => {
                    return (
                      <React.Fragment>
                        <div className="panel-text-title">{item.item}</div>
                        <div
                          className="panel-text-info"
                          dangerouslySetInnerHTML={{ __html: item.content }}
                        />
                      </React.Fragment>
                    );
                  })}
                </div>
              </div>
            )}
            <div className="view-content-title">历史版本</div>
            <Collapse
              bordered={false}
              className="about-view-collapse"
              expandIcon={({ isActive }) => (
                <Icon type="caret-right" rotate={isActive ? 90 : 0} />
              )}>
              {data.map((v, index) => {
                return (
                  <Panel
                    header={`${v.name} ${v.time}`}
                    key={index}
                    className="about-view-panel">
                    {v.function.map(item => {
                      return (
                        <div className="panel-text">
                          <div className="panel-text-title">{item.item}</div>
                          <div
                            dangerouslySetInnerHTML={{ __html: item.content }}
                          />
                        </div>
                      );
                    })}
                  </Panel>
                );
              })}
            </Collapse>
          </div>
    );
  }
}

export default UpdateInfo;
