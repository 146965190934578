import React, { Component } from 'react'
import UpdateInfo from './compontent/updateInfo';
import UploadApp from './compontent/uploadApp';
import UploadPdf from './compontent/uploadPdf';
const background = '/resource/image/Banner.svg';
// const Logo_Antelope = '/resource/image/Logo_Antelope.svg';
// const Logo_CloudEye = '/resource/image/Logo_CloudEye.svg';
// const Logo = '/resource/image/Logo.svg';

class AboutView extends Component {
  state = {
    tabIndex: 0,
  };
  changeTabPosition = tabPosition => {
    this.setState({ tabPosition });
  };
  render() {
    const navName = ['App下载', '文档下载', '升级说明']
    return (
      <div className="about-view">
        <div className="about-view-scoll">
          <div className="about-view-header">
            {/* <div className="top">
              <img className="about-view-logo" src={Logo} alt="" />
            </div> */}
            <img className="about-view-img" src={background} alt="" />
          </div>
          {/* <UpdateInfo /> */}
          <div className="content">
            <div className="tabNav">
              {navName.map((item, index) => 
                <div 
                  className={this.state.tabIndex == index ? 'tabItem active-item' : 'tabItem'} 
                  onClick={ () => { this.setState({ tabIndex: index })}}
                >{item}</div>
              )}
              
            </div>
            <div className="tabPane">
               {this.state.tabIndex == 0 ? <UploadApp /> : 
                this.state.tabIndex == 1 ? <UploadPdf /> :
                <UpdateInfo />
               }
            </div>
          </div>
        </div>
        {/* <div className="footer">
          <div className="logo-list">
            <img src={Logo_Antelope} alt="" />
            <img src={Logo_CloudEye} alt="" />
          </div>
          <div className="Copyright">Copyright  2015-2018  深圳羚羊极速科技有限公司  版权所有  京ICP备15033186号-1</div>
        </div> */}
      </div>
    )
  }
}
export default AboutView