import React, { Component } from "react";
import { message } from "antd";
class uploadApp extends Component {
  state = {
    info: {}
  };
  componentDidMount() {
    Service.other.appUpload().then(res => {
      this.setState({
        info: res
      });
    });
  }
  copyUrl(s) {
    if (window.clipboardData) {
      window.clipboardData.setData("text", s);
    } else {
      (function(s) {
        document.oncopy = function(e) {
          e.clipboardData.setData("text", s);
          e.preventDefault();
          document.oncopy = null;
        };
      })(s);
      document.execCommand("Copy");
      message.success("复制成功");
    }
  }
  render() {
    const {
      name,
      android = {},
      ios = {},
    } = this.state.info;
    return (
      <div className="uploadApp">
        <div className="content-title">二维码</div>
        <div className="content-c">
          <div className="app">
            <div className="top">Android版本</div>
            <div className="ewm">
              <img src={android.qrCodeUrl} alt="" />
            </div>
            <div className="app-name">
              {name}
              <span>{android.version}</span>
            </div>
            <div className="app-url">
              {android.downloadUrl}
              <span
                onClick={() => {
                  this.copyUrl(android.downloadUrl);
                }}
              >
                复制链接
              </span>
            </div>
          </div>
          <div className="app">
            <div className="top">ios版本</div>
            <div className="ewm">
              <img src={ios.qrCodeUrl} alt="" />
            </div>
            <div className="app-name">
              {name}
              <span>{ios.version}</span>
            </div>
            <div className="app-url">
              {ios.downloadUrl}
              <span
                onClick={() => {
                  this.copyUrl(ios.downloadUrl);
                }}
              >
                复制链接
              </span>
            </div>
          </div>
        </div>
        <div className="content-title">注意事项</div>
        <div className="content-foot">
          <div>安装过程：</div>
          <div>1、iOS下载安装之前请先卸载手机上已经安装过的智慧云眼APP。</div>
          <div>2、安装后第一次打开智慧云眼APP会提示要信任企业开发者证书。</div>
          <div />
          <div>企业开发者证书信任过程：</div>
          <div>1、打开设置，找到通用。</div>
          <div>2、在通用里找到描述文件与设备管理</div>
          <div>3、找到企业级应用SHENZHEN LINGYANG VIDEO CL…证书。</div>
          <div>4、点击证书，信任，然后回到桌面即可打开智慧云眼APP。</div>
        </div>
      </div>
    );
  }
}
export default uploadApp;
