import React, { Component } from "react";
const PDF = "/resource/image/PDF.svg";
class uploadPdf extends Component {
  state = {
    info: []
  };
  componentDidMount() {
    Service.other.pdfUpload().then(res => {
      this.setState({
        info: res.files
      });
    });
  }
  // upload(url, title) {
  //   Utils.tagAToDownload({ url, title });
  // }
  render() {
    return (
      <div className="uploadApp">
        <div className="content-title">文档列表</div>
        <div className="dmt-list">
          {this.state.info.map((item, index) => (
            <a
              className="list-item"
              key={index}
              href={item.url}
              download={item.name}
              target='_blank'
              rel="noopener noreferrer"
            >
              <img className="pdf-icon" src={PDF} alt="" />
              <div className="time">{item.time}</div>
              <span> {item.size} </span>
              <div className="name">{item.name}</div>
            </a>
          ))}
        </div>
      </div>
    );
  }
}
export default uploadPdf;
